import { FunctionComponent, HTMLAttributes, ReactElement } from "react";
import { Container, Grid, Typography } from "@mui/material";
import Image from "next/image";
import { styled } from "@mui/system";
import { fonts } from "~/theme";

import author from "./author.png";

interface Properties extends HTMLAttributes<HTMLElement> {}

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: "203px",
    marginBottom: "72px",
    [theme.breakpoints.down("sm")]: {
        marginTop: "64px",
        marginBottom: "64px",
    },
}));

const ContentGrid = styled(Grid)(({ theme }) => ({
    paddingRight: "48px",
    [theme.breakpoints.down("sm")]: {
        paddingRight: 0,
    },
}));

const Section1 = styled(Typography)(({ theme }) => ({
    ...fonts.bannerBigTextSM,
    fontSize: "48px",
    [theme.breakpoints.down("sm")]: {
        marginTop: "25px",
        fontSize: "32px",
        lineHeight: "34px",
    },
}));

const Section2 = styled(Typography)(({ theme }) => ({
    ...fonts.bannerBigTextSM,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "39px",
    marginTop: "106px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
        lineHeight: "28px",
        marginTop: "8px",
    },
}));

const Section3 = styled(Typography)(({ theme }) => ({
    ...fonts.bannerSmallTextSM,
    marginTop: "32px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
        lineHeight: "17px",
        marginTop: "16px",
    },
}));

export const About: FunctionComponent<Properties> = ({
    children,
    className,
    ...rest
}: Properties): ReactElement => {
    return (
        <StyledContainer className={className} {...rest}>
            <Grid container wrap="wrap-reverse">
                <ContentGrid item sm={8}>
                    <Section1>Paul Kneale</Section1>

                    <Section2>
                        Paul Kneale (b. 1986, Canada) explores the way in which
                        digital facets of our existence can be manifested and
                        reimagined in the flesh of the physical object
                    </Section2>

                    <Section3>
                        Works by Kneale have been included in the exhibition
                        Peindre la Nuit at Centre Pompidou Metz (October 2018),
                        Contemporary Photography Forum exhibition of the Boca
                        Raton Museum (Florida, USA), the Moscow International
                        Biennale for Young Art, the Rubell Family Collection and
                        at the prestigious Thetis Gardens in the Arsenale
                        Novissimo (Venice), in a group exhibition on view during
                        La Biennale di Venezia 57°. In Autumn 2019 he unveiled
                        Flat Earth Visa, a site-specific installation for the
                        hillscape surrounding the Palazzo Re Rebaudengo in
                        Guarene (Piedmont, Italy). He lives and works in
                        Toronto.
                    </Section3>
                </ContentGrid>
                <Grid item sm={4}>
                    <Image
                        width={519}
                        height={567}
                        src={author}
                        alt="Paul Kneale"
                        data-testid="author-image"
                    />
                </Grid>
            </Grid>
        </StyledContainer>
    );
};
